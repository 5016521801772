<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <Form v-if="model"
                :submit-text="pageTitle"
                :model="model"
                @on-submit="createModel" />
    <placeholder-form v-else />
  </div>
</template>

<script>
import Form from './_EditForm'
import OfficialService from '@admin/services/OfficialService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'

export default {
  components: { PlaceholderForm, Form },
  data () {
    return {
      pageTitle: '新建Banner',
      model: {
        b_des: '',
        url: '',
        img: '',
        is_web: 0,
        b_sort: 0
      }
    }
  },

  methods: {
    async createModel (model, success, callback) {
      const { data } = await flatry(OfficialService.create(model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({ path: '/official' })

        success()
      }

      callback()
    }
  }
}
</script>
