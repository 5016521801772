import Vue from 'vue'

const OfficialService = {
  all (param) {
    return Vue.http.get('official/index', param)
  },

  delete (id) {
    return Vue.http.delete('official/delete', { id: id })
  },

  create (admin = null) {
    const endpoint = 'official/create'

    if (admin === null) {
      return Vue.http.get(endpoint)
    }

    return Vue.http.post(endpoint, admin)
  },

  edit (id, admin = null) {
    const endpoint = 'official/update'

    if (admin === null) {
      return Vue.http.get(endpoint, { id: id })
    }

    return Vue.http.put(endpoint, admin, { id: id })
  }

}

export default OfficialService
