<template>
  <el-form
    v-if="model"
    :model="formModel"
    ref="formModel"
    label-width="100px"
    label-suffix="："
    @submit.native.stop.prevent="handleFormSubmit('formModel')"
  >
    <el-form-item
      label="描述"
      prop="b_des"
    >
      <el-col :md="9">
        <el-input v-model.trim="formModel.b_des"></el-input>
      </el-col>
    </el-form-item>
    <el-form-item
      label="封面图片"
      prop="img"
    >
      <uploader
        :avatar="formModel.img"
        @on-upload-success="handleUploadSuccess"
      />
    </el-form-item>
    <el-form-item label="显示顺序" prop="b_sort">
       <el-col :md="6">
        <el-input v-model.trim="formModel.b_sort"></el-input>
      </el-col>
    </el-form-item>

    <el-form-item
      label="是否为外网"
      prop="is_web"
    >
      <el-radio
        v-model="formModel.is_web"
        :label="0"
      >否</el-radio>
      <el-radio
        v-model="formModel.is_web"
        :label="1"
      >是</el-radio>
    </el-form-item>

    <el-form-item
      label="网址"
      prop="url"
    >
      <el-col :md="18">
        <el-input v-model.trim="formModel.url"></el-input>
      </el-col>
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        size="medium"
        native-type="submit"
        :loading="submitLoading"
      >{{ submitText }}</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import Uploader from '@admin/components/uploaders/Uploader'
export default {
  components: { Uploader },
  name: 'OfficialForm',
  props: {
    submitText: {
      type: String,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object
    }
  },
  data () {
    return {
      submitLoading: false,
      formModel: null
    }
  },
  mounted () {
    this.formModel = Object.assign(this.model)
  },
  methods: {
    handleUploadSuccess (avatarUrl) {
      this.formModel.img = avatarUrl
    },
    handleFormSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false
        }

        this.submitLoading = true

        this.$emit(
          'on-submit',
          this.formModel,
          () => {
            this.$refs[formName].clearValidate()
          },
          () => {
            this.submitLoading = false
          }
        )
      })
    }
  }
}
</script>
